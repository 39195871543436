.btn-outline-primary {
  border-width: 2px;
  @include elevation-2;
  @include button-outline-variant($primary);

  &.disabled,
  &:disabled {
    background-color: $basic-white;
    color: $gray-3;
    border-color: $gray-3;
    border-width: 2px;
  }
}

.btn-outline-secondary {
  border-width: 0;
  @include elevation-2;
  @include button-outline-variant($primary);

  &.disabled,
  &:disabled {
    background-color: $basic-white;
    color: $gray-3;
    border: none;
  }
}

.btn-outline-white {
  border-width: 2px;
  @include button-outline-variant($basic-white);
}
