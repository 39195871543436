.block-sites {
  .sites-grid {
    @include accordion-grid;

    .site-entry {
      overflow: hidden;
      @include elevation-2;
      @include elevation-transition;

      .site-image-wrapper {
        @include ratio-wrapper;
      }

      &:hover {
        @include elevation-1;
        @include elevation-transition;
        transform: translate(0, 4px);
      }
    }
  }
}
