$grid-breakpoints: (
  xs: 0,
  sm: 360px, // mobile
  md: 768px, // tablet
  lg: 1024px, // desktop
  xl: 1365px // desktop
);

:root {
  @each $breakpoint, $value in $grid-breakpoints {
    --#{$breakpoint}-breakpoint: #{$value};
  }
}

$container-max-widths: (
  xs: 375px,
  md: 720px,
  xl: 1140px,
);

$body-max-width: 1920px;
