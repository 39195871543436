.footer {
  .footer-logo {
    width: 128px;

    svg {
      width: 100%;
    }
  }

  a {
    i {
      font-size: 0.8em;
      margin-left: 0.5em;
    }
  }

  .active-lang {
    color: darken($basic-white, 50%);
  }
}
