.hero-content {
  .header-logo {
    max-height: 96px;
    width: auto;
    max-width: 100%;
  }
}

.hero-block {
  &.home-hero {
    .hero-image-wrapper {
      border-bottom: 5px solid $primary;
    }
  }
}
