#cookie-consent-overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.15);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 2000;
  display: none;
}

#cookie-consent {
  --transition-duration: 100ms;

  pointer-events: none;
  z-index: 2010;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: $body-max-width;

  #cookie-consent-accept {
    position: absolute;
    top: map-get($spacers, 3);
    right: map-get($spacers, 3);
  }

  .cookie-consent-info-window {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $white;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 80%;
      bottom: map-get($spacers, 5);
      left: calc(20% / 2);
    }
  }
}
