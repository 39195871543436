.latest-news-block {
  .news-grid {
    @include teaser-grid;

    .latest-news {
      @include elevation-2;
      @include elevation-transition;

      .latest-news-image-wrapper {
        @include ratio-wrapper(67.63%);

        .latest-news-image {
          @include ratio-image;
          @include teaser-image;
        }
      }

      &:hover {
        @include elevation-1;
        @include elevation-transition;
        transform: translate(0, 4px);
      }
    }
  }
}
