@import 'partials/slider';

.hero-block {
  .hero-content-wrapper {
    position: relative;
    z-index: 30;
  }

  .hero-image-wrapper {
    position: relative;
    z-index: 20;
    height: 480px;
    background-color: $gray-1;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      height: 705px;
    }

    &.no-image {
      height: auto;

      .hero-title-wrapper {
        position: relative;
        z-index: 10;
        pointer-events: none;

        .hero-title {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          h1, .h1,
          h2, .h2,
          h3, .h3,
          h4, .h4,
          h5, .h5,
          h6, .h6 {
            margin-bottom: 84px - 1 * $grid-gutter-width;
            margin-top: 6 * $grid-gutter-width;

            @include media-breakpoint-up(md) {
              margin-top: 7 * $grid-gutter-width;
              margin-bottom: 149px - 2 * $grid-gutter-width;
            }

            @include media-breakpoint-up(lg) {
              margin-top: 8 * $grid-gutter-width;
              margin-bottom: 170px - 2 * $grid-gutter-width;
            }
          }
        }
      }
    }

    .hero-title-wrapper {
      position: absolute;
      z-index: 10;
      width: 100%;
      height: 100%;
      pointer-events: none;

      .hero-title {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        h1, .h1,
        h2, .h2,
        h3, .h3,
        h4, .h4 {
          margin-bottom: 84px;

          @include media-breakpoint-up(md) {
            margin-bottom: 149px;
          }

          @include media-breakpoint-up(lg) {
            margin-bottom: 170px;
          }
        }
      }
    }

    .hero-image {
      width: auto;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    h1 {
      position: absolute;
    }
  }

  .hero-content {
    @include elevation-2;
    background-color: $basic-white;
    @include media-breakpoint-up(md) {
      margin-top: -(2 * $grid-gutter-width);
    }

    .hero-gallery-grid {
      display: grid;
      overflow: hidden;
      column-gap: 6px;

      .hero-gallery-image-wrapper {
        position: relative;
        opacity: 0.5;
        cursor: pointer;

        &.selected {
          opacity: 1;
        }

        &:hover {
          opacity: 1;
        }

        &:after {
          display: block;
          content: '';
          /* 19:10 aspect ratio */
          padding-bottom: 52.63%;
        }

        img {
          /* Image should match parent box size */
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .hero-submenu {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      background-color: $gray-1;

      @include media-breakpoint-up(md) {
        flex-direction: row;
      }
    }
  }
}
