// custom colors
$background-color: #FFFFFF;
$red: #dc0023;
$silver: #EBEBEB;
$silver-50: #EBEBEB80; // silver, 50%
$dark-blue: #00557A;
$blue: #0087C8;
$light-blue: #00AFF0;
$dark-green: #466400;
$green: #5A9114;
$light-green: #8CBE0F;
$dark-orange: #FA5A00;
$orange: #FF9400;
$light-orange: #FCC100;
$gray-6: #404040;
$gray-5: #595959;
$gray-4: #9C9C9C;
$gray-3: #CFCFCF;
$gray-2: #EBEBEB;
$gray-1: #F9F9F9;
$basic-black: #000000;
$basic-white: #FFFFFF;
$alert-green: #8CBE0F;
$alert-red: #DC0023;
$overlay-color: #00000026; // black, 15%
$elevation-color: #44444426; // dark grey, 15%

// bootstrap overrides
$primary: $red;
$secondary: $dark-blue;
$success: $green;
$info: $light-blue;
$warning: $light-orange;
$danger: $red;
$light: $gray-1;
$dark: $gray-6;

$gray-100: $gray-1;
$gray-200: $gray-2;
$gray-300: $gray-3;
$gray-400: $gray-4;
$gray-500: $gray-5;
$gray-600: $gray-6;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $basic-white,
  "dark": $dark,
  "gray-6": $gray-6,
  "gray-5": $gray-5,
  "gray-4": $gray-4,
  "gray-3": $gray-3,
  "gray-2": $gray-2,
  "gray-1": $gray-1,
);
