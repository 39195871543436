@mixin noto-sans-light($size: normal, $line-height: normal, $letter: initial) {
  font-family: 'noto-sans', sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: $size;
  line-height: $line-height;
  letter-spacing: $letter;
}

@mixin noto-sans-light-italic($size: normal, $line-height: normal, $letter: initial) {
  font-family: 'noto-sans', sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: $size;
  line-height: $line-height;
  letter-spacing: $letter;
}

@mixin noto-sans-regular($size: normal, $line-height: normal, $letter: initial) {
  font-family: 'noto-sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: $size;
  line-height: $line-height;
  letter-spacing: $letter;
}

@mixin noto-sans-regular-italic($size: normal, $line-height: normal, $letter: initial) {
  font-family: 'noto-sans', sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: $size;
  line-height: $line-height;
  letter-spacing: $letter;
}

@mixin noto-sans-semibold($size: normal, $line-height: normal, $letter: initial) {
  font-family: 'noto-sans', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: $size;
  line-height: $line-height;
  letter-spacing: $letter;
}

@mixin noto-sans-semibold-italic($size: normal, $line-height: normal, $letter: initial) {
  font-family: 'noto-sans', sans-serif;
  font-weight: 600;
  font-style: italic;
  font-size: $size;
  line-height: $line-height;
  letter-spacing: $letter;
}

@mixin elevation-1() {
  box-shadow: 0 1px 10px 0 #44444426;
}

@mixin elevation-2() {
  box-shadow: 0 2px 30px 0 #44444426;
}

@mixin elevation-transition() {
  transition: transform linear 100ms, box-shadow linear 100ms;
}

@mixin title-underline($line-color: $red, $line-distance: 32px, $line-width: 3px, $line-length: 128px) {
  padding-bottom: $line-distance;
  position: relative;

  &.text-light {
    &:after {
      background: $basic-white;
    }
  }

  &:after {
    content: "";
    background: $line-color;
    position: absolute;
    bottom: 0;
    left: 0;
    height: $line-width;
    width: $line-length;
  }
}

@mixin teaser-grid() {
  display: grid;
  grid-template-columns:  1fr;
  column-gap: $grid-gutter-width;
  row-gap: $grid-gutter-width;

  @include media-breakpoint-up(md) {
    grid-template-columns:  1fr 1fr;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns:  1fr 1fr 1fr;
  }
}

@mixin teaser-image($background-color: $teaser-image-bg-color) {
  border-bottom: none;
  background-size: cover;
  background-color: $background-color;
  background-repeat: no-repeat;
}

@mixin teaser-info-wrapper() {
  @include ratio-wrapper;
}

@mixin accordion-grid() {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: $grid-gutter-width;
  row-gap: $grid-gutter-width;

  @include media-breakpoint-up(md) {
    grid-template-columns:  1fr 1fr;
  }
}

@mixin custom-link(
  $color: $link-color,
  $border-bottom: $link-border-bottom,
  $border-padding-bottom: $link-border-padding-bottom,
  $hover-color: $link-hover-color,
  $hover-border-bottom: $link-hover-border-bottom,
  $hover-border-padding-bottom: $link-hover-border-padding-bottom,
  $hover-decoration: $link-hover-decoration,
) {
  &:not(.btn) {
    color: $color;
    border-bottom: $border-bottom;
    background-color: transparent; // Remove the gray background on active links in IE 10.
    border-bottom: none;
    padding-bottom: initial;
    transition: none;

    &.caret-link-after {
      &::after {
        font-family: 'Font Awesome 5 Free', sans-serif;
        font-weight: 900;
        content: '\a0\a0\f0da';
      }
    }

    &.caret-link-before {
      &::before {
        font-family: 'Font Awesome 5 Free', sans-serif;
        font-weight: 900;
        content: '\f0d9\a0\a0';
      }
    }

    @include hover() {
      color: $hover-color;
      border-bottom: $hover-border-bottom;
      text-decoration: $hover-decoration;
      border-bottom: none;
      padding-bottom: initial;
      transition: none;
    }
  }
}

@mixin navigation-element(
  $hover-color: $primary,
  $hover-border-bottom: $navigation-link-border-bottom,
  $selected-color: $primary,
  $border-bottom-width: 4px
) {
  @if $border-bottom-width {
    padding: map-get($spacers, 1) map-get($spacers, 1) (map-get($spacers, 1) - $border-bottom-width) map-get($spacers, 1);
  } @else {
    padding: map-get($spacers, 1)
  }

  @if $hover-border-bottom {
    border-bottom: $navigation-link-border-bottom;
    border-bottom-color: transparent;
    transition: border-bottom-color linear 100ms;
  }

  i {
    font-size: 0.8em;
    margin-left: 0.5em;
    pointer-events: none;
  }

  svg {
    max-height: 18px;
    width: auto;
    pointer-events: none;
  }

  &:hover {
    @if $hover-color {
      color: $hover-color;
    }
    @if $hover-border-bottom {
      border-bottom: $hover-border-bottom;
      transition: border-bottom-color linear 100ms;
    }
  }

  &.selected-nav-entry {
    @if $hover-border-bottom {
      color: $selected-color;
    }
  }

  @include media-breakpoint-up(lg) {
    @if $border-bottom-width {
      padding: map-get($spacers, 2) map-get($spacers, 2) (map-get($spacers, 2) - $border-bottom-width) map-get($spacers, 2);
    } @else {
      padding: map-get($spacers, 2);
    }
  }
}

@mixin menuicon($bg-color: $black, $height: 2px) {
  position: absolute;
  display: block;
  width: 100%;
  height: $height;
  background-color: $bg-color;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

@mixin hide-scrollbars() {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
}

@mixin dark-overlay() {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(0.84deg, #000000 44.79%, rgba(0, 0, 0, 0) 99.22%);
  opacity: 0.3;
  box-sizing: border-box;
}

@mixin buc-lists() {
  ul {
    list-style-type: none;
    margin: 1rem 0 1rem 0;
    padding-left: 0;

    li {
      position: relative;
      padding-left: 20px;
      margin-bottom: 5px;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 13px;
        width: 7px;
        border-top: 1px solid;
      }
    }
  }
}


/*
  16 : 9 => 56.23%
  21 : 9 => 42.85%
  4 : 3 => 75%
  1 : 1 => 100%
  usually wraps the ratio-image mixin
*/
@mixin ratio-wrapper($ratio: 56.23%, $height: 0, $width: 100%) {
  position: relative;
  padding-top: $ratio;
  height: 0;
  width: 100%;
}

// is usually wrapped by ratio-wrapper mixin
@mixin ratio-image(
  $object-fit: cover,
  $object-position: center,
  $width: 100%,
  $height: 100%,
  $top: 0,
  $right: 0,
  $bottom: 0,
  $left: 0,
) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  width: $width;
  height: $height;
  object-fit: $object-fit;
  object-position: $object-position;
}

@mixin hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

@mixin custom-control-label(
  $size: 1rem,
  $border-color: transparent,
  $border-width: 0px,
  $border-style: none,
  $background-color: #dee2e6,
  $top: .25rem,
  $left: 0
) {
  &::before {
    content: '';
    position: absolute;
    top: $top;
    left: $left;
    border: $border-width $border-style $border-color;
    display: block;
    width: $size;
    height: $size;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: $background-color;
  }

  &::after {
    content: '';
    position: absolute;
    top: $top;
    left: $left;
    border: $border-width $border-style $border-color;
    display: block;
    width: $size;
    height: $size;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }
}

@mixin custom-checkbox(
  $selected-bg-color: #007bff,
  $selected-border: unset,
) {
  .custom-control-input:checked {
    ~ .custom-control-label {
      &::before {
        background-color: $selected-bg-color;
        border: $selected-border,
      }

      &::after {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
        border: $selected-border,
      }
    }
  }
}

@mixin custom-control($min-height: 1.5rem, $padding-left: 1.5rem) {
  position: relative;
  display: block;
  min-height: $min-height;
  padding-left: $padding-left;
}

@mixin custom-control-input() {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

@mixin contact-form() {
  .flash-message {
    font-weight: 600;
  }

  form {
    .errors {
      color: $danger;
      @include noto-sans-regular(14px, 21px);
      padding: 0;
      margin: 0.5em 0 0 0;
      list-style: none;
    }

    .row {
      &:nth-last-child(2) {
        display: none !important
      }
    }
  }

  .custom-control-label {
    @include buc-lists;

    ul {
      margin-top: 1rem;
      margin-bottom: 0;
    }
  }
}
