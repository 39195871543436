.block-image-wall {
  .image-wall-grid {
    display: grid;
    grid-template-columns:  1fr;
    column-gap: $grid-gutter-width;
    row-gap: $grid-gutter-width;

    @include media-breakpoint-up(md) {
      grid-template-columns:  1fr 1fr;
    }

    @include media-breakpoint-up(xl) {
      grid-template-columns:  1fr 1fr 1fr 1fr;
    }

    .wall-image-wrapper {
      @include ratio-wrapper($ratio: 75%)
    }
  }
}
