.accordion-wrapper {
  .accordion-entry {
    .accordion-head {
      @include noto-sans-regular(18px, 25px);
      display: flex;
      flex-direction: row;
      cursor: pointer;
      color: $primary;
      transition: color .5s ease-in-out;

      .accordion-title {
        flex-grow: 1;
      }

      .accordion-chevron {
        i {
          -webkit-transition: -webkit-transform .8s ease-in-out;
          transition: transform .25s ease-in-out;
        }
      }

      &.collapsed {
        padding-bottom: 0 !important;
        color: initial;
        transition: padding-bottom .25s ease-in-out, color .5s ease-in-out;

        .accordion-chevron {
          i {
            transform: rotate(180deg);
            -webkit-transition: -webkit-transform .8s ease-in-out;
            transition: transform .25s ease-in-out;
          }
        }
      }
    }

    .accordion-decoration {
      border-bottom: 2px solid $gray-2;
      transition: border-bottom-color .5s ease-in-out;
    }

    .accordion-body.show ~ .accordion-decoration {
      border-bottom: 2px solid $primary;
      transition: border-bottom-color .5s ease-in-out;
    }
  }
}
