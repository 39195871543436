.block-timeline {
  .block-timeline-content-wrapper {
    position: relative;

    &.started,
    &.finished {
      .line {
        height: calc(100% + 45px);
        opacity: 1;

        &::after {
          transition: 300ms ease-in-out;
          top: 100%;
          bottom: auto;
        }
      }
    }

    &.finished {
      .line {
        &::after {
          background-size: 14px !important;
          width: 14px;
          height: 14px;
          margin: -14px 0 0 0;
          opacity: 1;
        }
      }
    }

    &.started {
      .line {
        &::after {
          opacity: 1;
        }
      }
    }

    .line {
      z-index: 1;
      position: absolute;
      left: -30px;
      width: 2px;
      background-color: #000;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      top: 0;
      bottom: auto;
      transition: 300ms ease-in-out;
      opacity: 0;
      height: 0;

      &::after {
        z-index: 999;
        content: '';
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='%23DC0023'%3E%3Cpath d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z'/%3E%3C/svg%3E") no-repeat center;
        background-size: 24px !important;
        width: 24px;
        height: 24px;
        margin: -24px 0 0 0;
        display: block;
        left: 50%;
        top: 0;
        bottom: auto;
        position: absolute;
        -webkit-transform: translate(-50%, 50%);
        -ms-transform: translate(-50%, 50%);
        transform: translate(-50%, 50%);
        opacity: 0;
        transition: 300ms ease-in-out;
      }
    }
  }
}
