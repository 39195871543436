$spacers: (
  0: 0,
  1: 16px, // XS tablet and mobile
  2: 20px, // XS desktop
  3: 30px, // S all devices
  4: 40px, // M tabled and mobile
  5: 60px, // M desktop, L tablet and mobile
  6: 90px, // L desktop, XL tablet and mobile
  7: 110px, // XL desktop
);
