.block-number-text-card {
  .card-number-wrapper {
    @include ratio-wrapper(67.63%);

    .card-number {
      @include noto-sans-semibold(96px);

      @include media-breakpoint-up(md) {
        @include noto-sans-semibold(56px);
      }

      @include media-breakpoint-up(xl) {
        @include noto-sans-semibold(75px);
      }

      overflow: hidden;
      color: $primary;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
  }

  .card-text-wrapper {
    @include ratio-wrapper(42.85%);

    .card-text {
      @include noto-sans-regular(18px);
      overflow: hidden;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;

      @include media-breakpoint-up(xl) {
        @include noto-sans-regular(30px);
      }
    }
  }
}
