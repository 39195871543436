body {
  @include noto-sans-regular(18px, 27px);
}

body.fonts-loaded {
  @include noto-sans-regular(18px, 27px);
}

h1, .h1 {
  color: $red;
  margin-bottom: 32px;

  &.underlined {
    @include title-underline();
  }

  @include noto-sans-light(56px, 63px);

  @include media-breakpoint-up(md) {
    @include noto-sans-light(80px, 80px);
  }

  @include media-breakpoint-up(lg) {
    @include noto-sans-light(96px, 96px, -0.5px);
  }
}

h2, .h2 {
  color: $red;
  margin-bottom: 32px;

  &.underlined {
    @include title-underline();
  }

  @include noto-sans-light(44px, 52px);

  @include media-breakpoint-up(md) {
    @include noto-sans-light(56px, 63px);
  }

  @include media-breakpoint-up(lg) {
    @include noto-sans-light(80px, 80px, -0.5px);
  }
}

h3, .h3 {
  color: $red;
  margin-bottom: 32px;

  &.underlined {
    @include title-underline();
  }

  @include noto-sans-regular(38px, 50px);

  @include media-breakpoint-up(lg) {
    @include noto-sans-regular(48px, 56px);
  }
}

h4, .h4 {
  color: $red;
  margin-bottom: 24px;

  &.underlined {
    @include title-underline($red, 24px, 2px, 96px);
  }

  @include noto-sans-regular(31px, 42px);
}

h5, .h5 {
  color: $red;
  margin-bottom: 24px;

  @include noto-sans-regular(23px, 31px);
}

h6, .h6 {
  color: $red;
  @include noto-sans-regular(18px, 25px);
}

.lead {
  @include noto-sans-regular(21px, 30px);
}

small, .small {
  @include noto-sans-regular(14px, 21px);
}

p {
  @include noto-sans-regular(18px, 27px);

  &.small {
    @include noto-sans-regular(14px, 21px);
  }
}
