.slider {
  width: 100%;
  height: 100%;
  position: relative;

  .swiper-container {
    width: 100%;
    height: 100%;
    --swiper-navigation-color: #{$white};
    --swiper-pagination-color: #{$white};
    --swiper-navigation-size: 16px;

    @include media-breakpoint-up(md) {
      --swiper-navigation-size: 20px;
    }

    .swiper-pagination-bullets {
      bottom: 30px;

      @include media-breakpoint-up(md) {
        bottom: 100px;
      }

      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        opacity: unset;
        background-color: transparent;
        border: solid 1px var(--swiper-pagination-color);

        &.swiper-pagination-bullet-active {
          background-color: var(--swiper-pagination-color);
        }
      }
    }

    .swiper-button-prev {
      font-weight: 700;
      display: none;

      @include media-breakpoint-up(sm) {
        left: 24px;
        display: block;
      }

      @include media-breakpoint-up(lg) {
        left: 64px;
        display: block;
      }

      @include media-breakpoint-up(xl) {
        left: 84px;
        display: block;
      }
    }

    .swiper-button-next {
      font-weight: 700;
      display: none;

      @include media-breakpoint-up(sm) {
        right: 24px;
        display: block;
      }

      @include media-breakpoint-up(lg) {
        right: 64px;
        display: block;
      }

      @include media-breakpoint-up(xl) {
        right: 84px;
        display: block;
      }
    }

    .swiper-wrapper {
      .swiper-slide {
        height: auto;
      }
    }
  }
}
