.team-accordion-grid {
  @include accordion-grid;

  .team-employee {
    overflow: hidden;
    @include elevation-2;

    .team-employee-image-wrapper {
      @include ratio-wrapper(67.63%);

      .team-employee-image {
        @include ratio-image;
        @include teaser-image;
        display: flex;
        align-items: center;
        justify-content: center;

        .team-employee-image-placeholder {
          color: $light;
          letter-spacing: -0.1em;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 128px;
          width: 128px;
          border-radius: 50%;
          background-color: $primary;
        }
      }
    }

    .team-employee-info-wrapper {
      .team-employee-info {
        .team-employee-info-contact {
          overflow-x: auto;
          overflow-y: hidden;
          white-space: nowrap;

          img {
            height: auto;
            width: 20px;
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
}
