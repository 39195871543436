#overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.15);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  display: none;
}

#navbar-toggle-button {
  --height: 30px;
  --width: 30px;
  --line-height: 2px;
  --offset: calc((var(--height) - (3 * var(--line-height))) / 4);
  --transition-duration: 100ms;

  display: flex;
  padding: 0;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background: none;

  .menuicon {
    position: relative;
    pointer-events: none;
    margin: 0;
    padding: 0;
    height: var(--height);
    width: var(--width);

    .menuicon-top {
      @include menuicon($primary, var(--line-height));
      top: calc(var(--offset));
      transition: transform var(--transition-duration) ease-in-out,
      top var(--transition-duration) var(--transition-duration) ease-in-out;
    }

    .menuicon-center {
      @include menuicon($primary, var(--line-height));
      top: calc((2 * var(--offset)) + var(--line-height));
      transition: opacity var(--transition-duration) var(--transition-duration) ease-in-out;
    }

    .menuicon-bottom {
      @include menuicon($primary, var(--line-height));
      top: calc((3 * var(--offset)) + (2 * var(--line-height)));
      transition: transform var(--transition-duration) ease-in-out,
      top var(--transition-duration) var(--transition-duration) ease-in-out;
    }

    &.navbar-open {
      .menuicon-top {
        transition: top var(--transition-duration) ease-in-out,
        transform var(--transition-duration) var(--transition-duration) ease-in-out;
        top: calc((2 * var(--offset)) + var(--line-height));
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      .menuicon-center {
        transition: opacity var(--transition-duration) ease-in-out;
        opacity: 0;
      }

      .menuicon-bottom {
        transition: top var(--transition-duration) ease-in-out,
        transform var(--transition-duration) var(--transition-duration) ease-in-out;
        top: calc((2 * var(--offset)) + var(--line-height));
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }
  }
}

.navigation {
  max-width: $body-max-width;
  z-index: $zindex-fixed;
  position: fixed;
  top: $grid-gutter-width;

  &.scrolling-down {
    .navigation-wrapper {
      .main-navigation {
        .main-navigation-wrapper {
          .main-navigation-entries {
            height: 90px;
            transition: height linear 150ms;
          }

          .meta-navigation-entries {
            height: 0;
            transition: height linear 150ms;
          }
        }
      }
    }
  }

  .navigation-wrapper {
    .main-navigation {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .navigation-logo {
        display: flex;
        align-items: center;
        min-width: 96px;
        max-width: 96px;

        svg {
          width: 100%;
        }

        @include media-breakpoint-up(lg) {
          min-width: 128px;
          max-width: 128px;
        }
      }

      .main-navigation-wrapper {
        overflow: hidden;

        .meta-navigation-entries {
          transition: height linear 150ms;
          overflow: hidden;
          height: 53px;

          ul {
            height: 100%;
            display: flex;
            flex-direction: row;

            li {
              &:first-child {
                margin-left: auto;
              }
            }

            a,
            div,
            span {
              &.meta-navigation-entry {
                padding: map-get($spacers, 1);
                @include noto-sans-regular(14px, 21px);
              }
            }

            a[target='_blank'] {
              &::after {
                font-size: 0.8em;
                margin-left: 0.5em;
                font-family: 'Font Awesome 5 Free', sans-serif;
                font-weight: 900;
                content: '\f35d';
              }
            }
          }
        }

        .main-navigation-entries {
          overflow-x: auto;
          transition: height linear 150ms;
          height: 67px;

          ul {
            height: 100%;
            display: flex;
            flex-direction: row;

            li {
              &:first-child {
                margin-left: auto;
              }
            }

            a,
            div {
              &.main-navigation-entry {
                @include navigation-element;

                &.selected {
                  border-bottom: $navigation-link-border-bottom;
                }
              }
            }

            div {
              &.main-navigation-entry {
                cursor: default;
              }
            }

            #desktop-search {
              button {
                @include navigation-element(null, null, null, null);

                svg {
                  g {
                    stroke: $body-color;
                  }
                }
              }
            }
          }
        }
      }

      .menu-burger {
        display: none;
      }
    }

    &.is-large-screen {
      .sub-navigation-wrapper {
        .sub-navigation-entry {
          border-top: transparent solid 0;
          height: 0;
          overflow-x: auto;
          @include hide-scrollbars;

          ul {
            display: flex;
            flex-direction: row;
            justify-content: center;

            a {
              @include navigation-element;
            }
          }

          &.show-sub-navigation {
            border-top: $nav-element-border-top;
            height: auto;
          }

          .mobile-main-navigation {
            display: none;
          }
        }
      }
    }

    &.is-small-screen {
      // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
      --current-height: calc(var(--vh, 1vh) * 100);
      max-height: calc(var(--current-height) - 2 * #{$grid-gutter-width});
      overflow-y: auto;
      @include hide-scrollbars;

      .main-navigation {
        position: sticky;
        z-index: $zindex-sticky;
        top: 0;

        .meta-navigation-entries {
          display: none;
        }

        .main-navigation-entries {
          display: none;
        }

        .menu-burger {
          display: flex;
          flex-direction: row;
          flex-grow: 1;
          justify-content: flex-end;
          align-items: center;
        }
      }

      .sub-navigation-wrapper {
        .sub-navigation-entry {
          border-top: $nav-element-border-top;
          height: auto;
          display: flex;
          flex-direction: column;

          ul {
            a, div {
              @include navigation-element($primary, null, null, null);
            }
          }

          &#sub-search-wrapper {
            border-top: none;
          }

          .mobile-main-navigation {
            display: flex;
            flex-direction: row;
            align-items: center;
            transition: color linear 150ms;

            &.selected {
              color: $primary;
              transition: color linear 150ms;

              button {
                i {
                  transform: rotate(180deg);
                  -webkit-transition: -webkit-transform .8s ease-in-out;
                  transition: transform .25s ease-in-out;
                }
              }
            }

            li {
              &:first-child {
                flex-grow: 1;
              }

              button {
                padding: map-get($spacers, 1);

                i {
                  -webkit-transition: -webkit-transform .8s ease-in-out;
                  transition: transform .25s ease-in-out;
                }
              }
            }
          }

          .mobile-sub-navigation {
            margin-left: map-get($spacers, 1);

            @include noto-sans-regular(14px, 21px);

            a {
              padding-top: 0;
            }
          }
        }
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      padding: 0;
      list-style-type: none;

      a, div, button {
        height: 100%;
        display: flex;
        white-space: nowrap;
        align-items: center;
        background: none;
        border: none;
        color: inherit;
        font: inherit;
        outline: inherit;
        padding: 0;
      }
    }
  }
}
