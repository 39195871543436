// links
$link-color: $primary;
$link-decoration: none;
$link-border-bottom: 2px solid $link-color;
$link-border-padding-bottom: 0px;
$link-hover-color: $link-color;
$link-hover-decoration: underline;
$link-hover-border-bottom: 2px solid $link-color;
$link-hover-border-padding-bottom: 3px;

$emphasized-link-hover-darken-percentage: 0%;

// buttons
$btn-border-radius: 0;

// text
$font-weight-bold: 600;
$font-weight-bolder: 600;
$font-weight-normal: 400;
$font-weight-light: 300;
$font-weight-lighter: 300;

// teaser
$teaser-image-bg-color: $gray-1;

// navigation
$navigation-link-border-bottom: $primary solid 4px;

// nav links
$nav-tabs-border-color: none; // override
$nav-tabs-border-width: 0; // override
$nav-tabs-border-radius: 0; // override

// nav elements
$nav-element-border-top: $silver solid 2px;

// form
$input-border-color: $basic-black;
$input-border-radius: 0;
$input-border-radius-lg: 0;
$input-border-radius-sm: 0;
$input-disabled-bg: transparent;

// close
$close-color: $red;

// modal
$modal-backdrop-bg: $basic-black;
$modal-backdrop-opacity: 0.15;
$modal-footer-border-width: 0;
$modal-header-border-width: 0;
$modal-content-border-width: 0;
$modal-content-border-radius: 0;
