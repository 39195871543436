* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  max-width: $body-max-width;
  margin-left: auto;
  margin-right: auto;
}

:root {
  --grid-gutter-width: #{$grid-gutter-width};
}

a[type='button'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

a[target='_blank'] {
  &::after {
    font-size: 0.8em;
    margin-left: 0.5em;
    font-family: 'Font Awesome 5 Free', sans-serif;
    font-weight: 900;
    content: '\f35d';
  }
}

a:not(.nav-link) {
  @include custom-link;

  &.light-link {
    color: $basic-white;

    @include hover() {
      color: darken($basic-white, 10%);
    }
  }

  &.no-link-style {
    color: unset;
    border-bottom: unset;
    background-color: unset;
    padding-bottom: unset;
    transition: unset;

    @include hover() {
      color: unset;
      border-bottom: unset;
      padding-bottom: unset;
      transition: unset;
      text-decoration: unset;
    }

    &::after {
      content: unset;
    }
  }
}

.spinner-display-none {
  display: none;
}

.container-fluid {
  max-width: 1440px;
}

.elevation-1 {
  @include elevation-1;
}

.elevation-2 {
  @include elevation-2;
}

.dark-overlay {
  @include dark-overlay;
}

.cursor-auto {
  cursor: auto !important;
}

.anchor {
  display: block;
  position: relative;
  top: -5 * $grid-gutter-width;
  visibility: hidden;

  @include media-breakpoint-up(lg) {
    top: -6 * $grid-gutter-width;
  }

  &.is-teaser {
    display: initial;
    position: initial;
    visibility: initial;
    top: 0;
  }
}

.nav-tabs {
  border-bottom: 2px solid $gray-2 !important;

  &.nav {
    flex-wrap: nowrap;

    .nav-item {
      flex-shrink: 0;
    }
  }

  .nav-item {
    .nav-link {
      margin-bottom: 2px;
      color: unset;

      @include hover-focus-active() {
        margin-bottom: -2px;
        border-bottom: 4px solid $primary;
        color: $primary;
      }

      &.active {
        margin-bottom: -2px;
        border-bottom: 4px solid $primary;
        color: $primary;
      }
    }
  }
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.form-control {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;

  &:disabled,
  &[readonly] {
    opacity: 0.5 !important;
    border-color: rgba(0, 0, 0, 0.5);
  }
}

textarea {
  &.form-control {
    border: unset;
    @include elevation-2;
    padding: map-get($spacers, 2);
  }
}

.hyphenate {
  @include hyphenate;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  .hyphenate-#{$breakpoint} {
    @include media-breakpoint-up($breakpoint) {
      @include hyphenate;
    }
  }

  .hyphenate-#{$breakpoint}-none {
    @include media-breakpoint-up($breakpoint) {
      overflow-wrap: unset;
      word-wrap: unset;
      -webkit-hyphens: unset;
      -ms-hyphens: unset;
      -moz-hyphens: unset;
      hyphens: unset;
    }
  }
}

.download-decoration {
  height: 2px;
  width: 256px;
  margin-top: map-get($spacers, 2);
  background-color: $silver;
}

.custom-control {
  @include custom-control($padding-left: 2rem);
}

.custom-checkbox {
  @include custom-checkbox($selected-bg-color: $primary);
}

.custom-control-input {
  @include custom-control-input;
}

.custom-control-label {
  @include custom-control-label(
    $border-color: $basic-black,
    $border-width: 2px,
    $border-style: solid,
    $background-color: transparent,
    $size: 20px
  );
}

.buc-lists {
  @include buc-lists;
}


// https://en.wikipedia.org/wiki/Aspect_ratio_(image)
$ratios: ();
$ratios: map-merge(
    (
      "1-1": 100%, // square
      "4-3": 75%, // traditional television
      "16-9": 56.23%, // hd video standard
      "21-9": 42.85%, // cinematic widescreen
      "32-9": 36.23% // super ultrawide
    ),
    $ratios
);

@each $ratio, $value in $ratios {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      .ratio-wrapper-up-#{$breakpoint}-#{$ratio} {
        @include ratio-wrapper($value);
      }
    }

    @include media-breakpoint-down($breakpoint) {
      .ratio-wrapper-down-#{$breakpoint}-#{$ratio} {
        @include ratio-wrapper($value);
      }
    }
  }
}

.ratio-image {
  @include ratio-image;
}

.teaser-image {
  @include teaser-image;
}

.search-form-wrapper {
  form {
    display: flex;
    justify-content: center;

    .input-group {
      max-width: 720px;

      input {
        @include noto-sans-regular(18px, 27px);
        height: 46px;
        padding-left: 15px;
        padding-right: 15px;
        background-color: $gray-2;
        border-radius: 2px;
        border: none;

        &::placeholder {
          color: $gray-4;
          opacity: 1;
        }

        &:-ms-input-placeholder {
          color: $gray-4;
        }

        &::-ms-input-placeholder {
          color: $gray-4;
        }

        &:focus {
          border-color: unset;
          box-shadow: unset;
          outline: 0;
        }
      }

      .input-group-text {
        border: none;
        border-radius: 2px;
        background-color: $gray-2;
        color: $gray-4;

        svg {
          width: 21px;
          height: 21px;
        }

        &:focus {
          border-color: unset;
          box-shadow: unset;
          outline: 0;
        }
      }
    }
  }
}

.elevation-transition {
  @include elevation-2;
  @include elevation-transition;

  &:hover {
    @include elevation-1;
    @include elevation-transition;
    transform: translate(0, 4px);
  }
}

.close {
  opacity: 1;
}

.assitive-text {
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
  top: -10px;
}

.no-last-child-margin-bottom {
  * {
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}

.visually-hidden {
  border: 0 none;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0 !important;
  position: absolute;
  width: 1px;
  margin: 0 !important;
  line-height: 1px;
  font-size: 1px;
}
