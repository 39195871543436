.block-logo-wall {
  .block-logo-wall-wrapper {
    min-height: 192px;
    max-height: 192px;
    width: 100%;

    .block-logo-wall-card {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
